

export const Urls = {
    cloud_storage_prefix: "https://storage.googleapis.com/biomembhub/"
};

export const Databases = {
    OPRLM: "https://oprlm.org/",
    MEMBRANOME: "https://biomembhub.org/membranome/",
    PERMM: "https://biomembhub.org/permm/",
    OPM: "https://opm.phar.umich.edu/",
    MEMBRANOMEX: "",
}

export const Webservers = {
    PPM: "https://opm.phar.umich.edu/ppm_server",
    OPRLM: "https://oprlm.org/oprlm_server",
    FMAP: "https://biomembhub.org/membranome/fmap_cgopm",
    TMDOCK: "https://biomembhub.org/membranome/tmdock_cgopm",
    TMPfold: " https://opm.phar.umich.edu/tmpfold_server_cgopm",
    TMnet: "https://biomembhub.org/membranome/1tmnet",
    PERMM: "https://biomembhub.org/permm/",
    CELLPM: "https://biomembhub.org/cellpm/",
}

export const Links = {
    Lehigh_University: "https://www2.lehigh.edu",
    Department_of_Biological_Sciences: "https://www.lehigh.edu/~inbios/",
    Department_of_Chemistry: "https://chemistry.cas.lehigh.edu",
    Department_of_Bioengineering: "https://engineering.lehigh.edu/bioe",
    Im_Lab: "https://compbio.lehigh.edu/",
    Lomize_Group: "https://pharmacy.umich.edu/lomize-group/",
    BitBucket: "https://bitbucket.org/lomize-group/workspace/overview/",
    GitLab: "https://cggit.cc.lehigh.edu/groups/biomembhub"
}